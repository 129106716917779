import React from "react";
import { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import { useAuthContext } from "../../context/auth";

import password from "../../assets/icons/profile/Lock.svg";
import deleteImg from "../../assets/icons/profile/delete.svg";
import avatar from "../../assets/icons/profile/avatar.svg";
import logout from "../../assets/icons/profile/logout.svg";
import { Fade } from "react-reveal";
import img2 from "../../assets/icons/profileAccord/img2.svg";
import img4 from "../../assets/icons/profileAccord/img4.svg";
import img6 from "../../assets/icons/profileAccord/img6.svg";

import win_download from "../../assets/icons/windows_icon.svg";
import mac_download from "../../assets/icons/apple_icon.svg";
import guides from "../../assets/icons/download.svg";

import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import { Link } from "react-router-dom";

import "./index.scss";
import LogOutComponent from "../../components/LogOut";

const ProfilePage = () => {
  const { user } = useAuthContext();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="profile">
      <Fade>
      <div className="profile-container">
        <div className="profile-container-left">
          <div className="profile-container-left-info">
            <Avatar className="profile-container-left-info-avatar" alt="Remy Sharp" src={avatar} />
            <div className="profile-container-left-info-block">
            <h1 className="profile-container-left-info-name">{`${user?.firstName} ${user?.lastName}`}</h1>
            <h2 className="profile-container-left-info-email">{user?.email}</h2>
            </div>

          </div>
          <ul className="profile-container-left-list">
            <li>
              <img src={password} alt="" />
              <div className="profile-container-left-list-text">
                <Link to={"/change-password"}>Change password</Link>
              </div>
            </li>
            <li>
              <img src={deleteImg} alt="" />
              <div className="profile-container-left-list-text">
                <Link to={"/delete-account"}>Delate account</Link>
              </div>
            </li>
            <div className="profile-container-left-list-text-margin" />

            <LogOutComponent />
          </ul>
        </div>
        <div className="profile-container-main">
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <img src={img2} alt="" />
                  <h3>Download or update EDA</h3>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <Link className="globalBtn profile-container-main-btn" alt="" to={"/prepare?download=win"}>
                  Atlas EDA for Windows <img src={win_download} alt="" />
                </Link>
                <Link className="globalBtn profile-container-main-btn" alt="" to={"/prepare?download=mac"}>
                  Atlas EDA for Mac OS
                  <img src={mac_download} alt="" />
                </Link>
                <Link className="globalBtn profile-container-main-btn" alt="" to={"/prepare?download=guide"}>
                  Get Atlas EDA User Guide
                  <img src={guides} alt="" />
                </Link>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <Link to={"/contact?data=tech"}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <img src={img6} alt="" />
                    <h3>Contact technical support</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
              </Link>
            </AccordionItem>

            <AccordionItem>
              <Link to={"/contact?data=find"}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <img src={img4} alt="" />
                    <h3>Find a supplier</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
              </Link>
            </AccordionItem>
          </Accordion>
        </div>
        <ul className="profile-container-left-list mobile-view">
        <LogOutComponent />
            </ul>
      </div>
      </Fade>
    </div>
  );
};

export default ProfilePage;
