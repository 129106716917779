export const ROUTES = {
    root: '/',
    quality: '/quality',
    about: '/about',
    asic: '/asic',
    power: '/power-delivery',
    services: '/services',
    policy: '/policy',
    article: '/article',
    articleUasic: '/articleUasic',
    contact: '/contact',
    edea: '/edea',
    download: '/download',
    login: '/sign-in',
    reg: '/sign-up',
    prepare: '/prepare',
    profile: '/profile',
    confirm: '/confirmEmail',
    forgot: '/forgot-password',
    reset: '/reset-password',
    change: '/change-password',
    deleteRoute: '/delete-account',
}
const apiDev = false
export const API = "https://api.atlasmagnetics.com/contact/index.php"
export const CDN_server = "https://cdn.atlasmagnetics.com/"
export const TRACKING_ID = "G-19KN3FBCP6";
export const AUTH_TOKEN = "authToken";
export const BEARER = "Bearer";
export const ApiUrl = apiDev ? 'http://194.187.154.148:1337/api' : 'https://atlas.sivium.solutions/api'