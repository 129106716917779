import { useEffect } from "react";
import { Link } from "react-router-dom";
import SEO from "../../components/seo";
import ameda from "../../assets/icons/ameda.svg"
import bannerAmeda from "../../assets/images/articleDataBanner.png"
import "./index.scss";
import { Fade } from "react-reveal";

const ArticleUasic = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (<>
    <SEO title={"Programmable Mixed-Signal µASICs, DC/DC modules, Power delivery | Atlas Magnetics"} description={"Atlas Magnetics produces low cost programmable mixed-signal µASICs, thin DC/DC converters, PMIC, load switch IC for various applications. Contact us for custom IC solutions."} type={"page"} keywords={"Atlas Magnetics, Inductors, IC, semiconductors, power delivery IC, mixed signal IC, programmable IC, ASIC, µASICs, micro Asic, FPGA"} />
    <div className="articleUasic">
      <div className="articleUasic-description">
        <img src="https://cdn.atlasmagnetics.com/chip.png" alt="logo" className="img-header" />
        <br />
        <br />
        <h4>We are excited to announce the launch of our latest product, the 8 Pin uASIC AM1U1108! </h4>
        <hr />
        <p>
          <Fade>
          uASIC products are a designer’s best solution for low-power, easy integration of can’t-fail functions that are often begrudgingly implemented with discrete circuitry or off-the-shelf, inflexible parts. With uASIC users can create a design that looks and feels like a custom ASIC without the development time or cost. The AM1U1108 delivers this capability to customers in a tiny 1.0mm x 1.2mm package containing dozens of building blocks for unique configurability.
          </Fade>
          <br />
        </p>
        <br />
        <Fade>
        <img src={bannerAmeda} className="articleUasic-description-image" alt="" />
        </Fade>
        <br />
        <p>
          Key Features of AM1U1108:
          <br />

        </p>
        <Fade>
        <ul>
          <li>Wide Supply Voltage Range: 1.71V to 5.5V</li>
          <li>Extended Operating Temperature Range: -40°C to 85°C</li>
          <li>Two Combinatorial 2-bit Look Up Tables (LUTs)</li>
          <li>Nine Multifunctional Macrocells</li>
          <li>Three 8-bit Timers</li>
          <li>Oscillator (25kHz/2MHz)</li>
          <li>Data protection feature (CRC, CRV)</li>
        </ul>
        </Fade>
        <Fade>
        <p>
       
          To explore all the features of the AM1U1108, please download the datasheet. <Link className="globalBtn asic-description-btn" alt="" to={'/edea'}>Data Sheet</Link>
        
          <br />
          <br />
          <br />
        </p>
        <p>
          The AM1U1108 can be quickly configured using our user-friendly schematic-capture tool, Atlas EDA. This powerful software enables designers to easily program and customize the uASIC, making the entire process seamless and efficient. By harnessing the full potential of Atlas EDA, users can unlock the optimal performance of the AM1U1108 and achieve exceptional results for their projects.
          <br />

        </p>
        <br />
        <Link className="globalBtn articleUasic-description-btn btn-acticle" alt="" to={'/edea'}> <img src={ameda} alt="" />Learn more and download Atlas EDA for free</Link>
        </Fade>

        <br />





      </div>
      <div className="articleUasic-description-contact">
        <p>Contact our team to get your 8 Pin uASIC AM1U1108 today and experience the power of customization firsthand.</p>
        <br/>
        <Link className="globalBtn asic-description" alt="" to={'/contact'}>Contact us</Link>
      </div>
    </div>
  </>);
};

export default ArticleUasic;
