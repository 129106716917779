import { useEffect } from "react";
import { Fade } from "react-reveal";
import SEO from "../../components/seo";
import "./index.scss";

import changible from "../../assets/icons/changible.svg"
import good from "../../assets/icons/good.svg"
import money from "../../assets/icons/money.svg"
import outside from "../../assets/icons/outside.svg"
import poverV1 from "../../assets/icons/poverV1.svg"
import reload from "../../assets/icons/reload.svg"
import ameda from "../../assets/icons/ameda.svg"
import { Link } from "react-router-dom";
import VideoPlayer from "../../components/VideoPlayer";

const Asic = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (<>
    <SEO title={"Configurable µASIC - Mixed Signal Programmable Micro ASICs (µASICs) | Atlas Magnetics"} description={"Atlas Magnetics offers flexible programmable micro ASICs (µASICs)  that can replace several discrete ICs. Our micro ASICs are super low power and low cost, perfect for any application requiring mixed signal capabilities"} type={"page"} keywords={"Programmable ASICs, Mixed signal ASICs, µASIC,  microASIC, micro asic, uASIC, low power programmable IC, low cost programmable IC, PWM generator, Glue logic, Power Sequencer,Power on Reset, POR IC, LED controller IC, Voltage monitoring, I2C, State Machine, Wake and sleep generator, EDA for ASICs, Replace FPGA, Cheaper than FPGA"} />
    <div className="asic">
      <div className="asic-header">
        <Fade>
          <h2>Configurable µASIC</h2>
        </Fade>
      </div>
      <div className="asic-description">
        <Fade>
          <h4>Fixing the Problem of Custom ASICs</h4>
        </Fade>
        <p>
          <Fade>
            Board Designers love a custom ASIC solution, but finding a perfect fit already on the market is unlikely when creating a new, differentiated product. Creating or spending money on a completely new ASIC is out of the question for most project budgets, but using a discrete or imperfect solution with off-the-shelf parts is painful. What other options exist?
          </Fade>
          <br />
          <Fade>
            µASIC is our answer for engineers who want the look and feel of an ASIC without the time, cost, and contracts that come with developing a custom ASIC. Using the macrocell toolkit unique to each uASIC a user can create and combine essential functions like power sequencing, system resets, voltage monitoring, and more. Once the design is ready for implementation Atlas Magnetics provides a custom part marking and custom datasheet to keep your end design confidential and easily orderable.
          </Fade>
          <br />
          <Fade>
          <span>Need help creating your uASIC design? <Link to={"/contact"} className="globalLink">Contact us here</Link> for one of our engineers to support you within 4 hours.</span>
          </Fade>
          <br />
          <br />
          <VideoPlayer src="https://cdn.atlasmagnetics.com/video/uASIC_video_1_1.mp4" poster="https://cdn.atlasmagnetics.com/AtlasEDA_Black.png" />
        </p>

      </div>
      <div className="asic-description paddingTopMobile">
        <Fade>
          <h4>µASICs Features</h4>
        </Fade>
        <p>
          <Fade>
            Atlas Magnetics is set to introduce its initial offering in a series of high-performing, energy-efficient, asynchronous µASICs for clients seeking reliable functionalities tailored to their precise system needs. Targeting applications such as control, sensing, and sequencing, these products possess the following characteristics:
          </Fade>
          <br />
        </p>
        <ul className="asic-description-list">
          <li><img src={poverV1} alt="poverV1" />&#8226;&nbsp;<div className="asic-description-list-text"><b>Low-power</b>&nbsp; – less than 1uA if analog block is not used.</div></li>
          <li><img src={money} alt="money" />&#8226;&nbsp;<div className="asic-description-list-text"><b>Low cost/Smaller Area</b>&nbsp; – replace several discrete Ics.</div></li>
          <li><img src={reload} alt="reload" />&#8226;&nbsp;<div className="asic-description-list-text"><b>Asynchronous design</b>&nbsp; – responds more quickly than processor/microcontroller designs.</div></li>
          <li><img src={outside} alt="outside" />&#8226;&nbsp;<div className="asic-description-list-text"><b>Any-to-any connections</b>&nbsp; – any macrocell can be routed to any other macrocell, not constrained to adjacent cells like an FPGA.</div></li>
          <li><img src={good} alt="good" />&#8226;&nbsp;<div className="asic-description-list-text"><b>Uniform macrocells within family</b>&nbsp; – easy to transfer and upgrade design as needed.</div></li>
          <li><img src={changible} alt="changible" />&#8226;&nbsp;<div className="asic-description-list-text"><b>Higher Quality</b>&nbsp; – reducing # parts, configured by hardware, not software.</div></li>
        </ul>
        <br />
        <VideoPlayer src="https://cdn.atlasmagnetics.com/video/uASIC_video_2_1.mp4" poster="https://cdn.atlasmagnetics.com/AtlasEDA_Black.png" />
      </div>





      <div className="asic-description paddingTopMobile">
        <Fade>
          <h4>Process of designing a custom IC</h4>
        </Fade>
        <p>
          <Fade>
            At Atlas Magnetics, we strive to exceed your expectations by providing a seamless experience from design to delivery. Our advanced µASIC technology enables us to rapidly configure and program ICs according to your specifications, resulting in a highly tailored solution that perfectly integrates fits your system.
          </Fade>
          <br />
          <Fade>
            Once a design is submitted to Atlas Magnetics a custom part Datasheet and Part Marking are assigned. Samples are available within days, and in no more than 8 weeks from submitting your design Atlas Magnetics is able to support your full production volume.
          </Fade>
          <br />
          <Fade>
            Our application team is ready to bring your design to life, tailored to your specific technical requirements. Alternatively, you have the option to create your own custom ASIC using our highly intuitive Schematic-Capture Tools, ATLAS EDA.
          </Fade>
          <br />
          <Link className="globalBtn asic-description-btn" alt="" to={'/edea'}> <img src={ameda} alt="" />Learn more and download Atlas EDA for free</Link>
          <br />
        </p>
        <br />
        <br />
        <VideoPlayer src="https://cdn.atlasmagnetics.com/video/uASIC_video_3_1.mp4" poster="https://cdn.atlasmagnetics.com/AtlasEDA_Black.png" />
      </div>
      <div className="asic-description paddingTopMobile">
        <Fade>
          <h4>Product list</h4>
        </Fade>
        <p className="asic-description-box">
          <table className="asic-description-table" cellSpacing="0" cellPadding="0">
            <tr className="asic-description-table-header">
              <th>Base Die</th>
              <th>GPIO</th>
              <th>Supply range</th>
              <th>ACMP</th>
              <th>Package size, mm</th>
              <th>TMR</th>
              <th>LUT</th>
              <th>DFF / LATCH</th>
              <th>Shift Register</th>
              <th>Prog. DLY</th>
              <th>Pattern Gen</th>
              <th>OSC</th>
              <th>IC</th>
              <th>Temp Sensor</th>
              <th>W/S controller</th>
              <th>Vref</th>
              <th>Data sheet</th>
            </tr>
            <tr className="asic-description-table-body">
              <td className="asic-description-table-body-first">AM1U1108</td>
              <th>6</th>
              <th className="asic-description-table-body-smallF">1.71V to 5.5V</th>
              <th>-</th>
              <th>1x1.2</th>
              <th>4</th>
              <th>10</th>
              <th>6</th>
              <th>1</th>
              <th>1</th>
              <th>-</th>
              <th className="asic-description-table-body-smallF">25kHz/ 2MHz</th>
              <th>-</th>
              <th>-</th>
              <th>-</th>
              <th>-</th>
              <th><Link to={'#'}>Coming soon</Link></th>
            </tr>
            <tr className="asic-description-table-body">
              <td className="asic-description-table-body-first">AM1U1112</td>
              <th>9</th>
              <th className="asic-description-table-body-smallF">1.71V to 5.5V</th>
              <th>2</th>
              <th>1.6x1.6</th>
              <th>8</th>
              <th>26</th>
              <th>15</th>
              <th>1</th>
              <th>1</th>
              <th>4</th>
              <th className="asic-description-table-body-smallF">25kHz/ 2MHz 25MHz</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th><Link to={'#'}>Coming soon</Link></th>
            </tr>
            <tr className="asic-description-table-body">
              <td className="asic-description-table-body-first">AM1U1212</td>
              <th>10</th>
              <th className="asic-description-table-body-smallF">1.71V to 5.5V</th>
              <th>2</th>
              <th>1.6x1.6</th>
              <th>8</th>
              <th>26</th>
              <th>15</th>
              <th>1</th>
              <th>1</th>
              <th>4</th>
              <th className="asic-description-table-body-smallF">25kHz/ 2MHz 25MHz</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th><Link to={'#'}>Coming soon</Link></th>
            </tr>
            <tr className="asic-description-table-body">
              <td className="asic-description-table-body-first">AM1U1116</td>
              <th>13</th>
              <th className="asic-description-table-body-smallF">1.71V to 5.5V</th>
              <th>2</th>
              <th>1.8x2.6</th>
              <th>8</th>
              <th>26</th>
              <th>15</th>
              <th>1</th>
              <th>1</th>
              <th>4</th>
              <th className="asic-description-table-body-smallF">25kHz/ 2MHz 25MHz</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th><Link to={'#'}>Coming soon</Link></th>
            </tr>
            <tr className="asic-description-table-body">
              <td className="asic-description-table-body-first">AM1U1216</td>
              <th>14</th>
              <th className="asic-description-table-body-smallF">1.71V to 5.5V</th>
              <th>2</th>
              <th>1.8x2.6</th>
              <th>8</th>
              <th>26</th>
              <th>15</th>
              <th>1</th>
              <th>1</th>
              <th>4</th>
              <th className="asic-description-table-body-smallF">25kHz/ 2MHz 25MHz</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th><Link to={'#'}>Coming soon</Link></th>
            </tr>
            <tr className="asic-description-table-body">
              <td className="asic-description-table-body-first">AM1U1120</td>
              <th>17</th>
              <th className="asic-description-table-body-smallF">1.71V to 5.5V</th>
              <th>4</th>
              <th>2x3</th>
              <th>8</th>
              <th>26</th>
              <th>15</th>
              <th>1</th>
              <th>1</th>
              <th>4</th>
              <th className="asic-description-table-body-smallF">25kHz/ 2MHz 25MHz</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th>2</th>
              <th><Link to={'#'}>Coming soon</Link></th>
            </tr>
            <tr className="asic-description-table-body">
              <td className="asic-description-table-body-first">AM1U1220</td>
              <th>18</th>
              <th className="asic-description-table-body-smallF">1.71V to 5.5V</th>
              <th>4</th>
              <th>2x3</th>
              <th>8</th>
              <th>26</th>
              <th>15</th>
              <th>1</th>
              <th>1</th>
              <th>4</th>
              <th className="asic-description-table-body-smallF">25kHz/ 2MHz 25MHz</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th>2</th>
              <th><Link to={'#'}>Coming soon</Link></th>
            </tr>
            <tr className="asic-description-table-body">
              <td className="asic-description-table-body-first">AM1U1124</td>
              <th>21</th>
              <th className="asic-description-table-body-smallF">1.71V to 5.5V</th>
              <th>4</th>
              <th>3x3</th>
              <th>8</th>
              <th>26</th>
              <th>15</th>
              <th>1</th>
              <th>1</th>
              <th>4</th>
              <th className="asic-description-table-body-smallF">25kHz/ 2MHz 25MHz</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th>2</th>
              <th><Link to={'#'}>Coming soon</Link></th>
            </tr>
            <tr className="asic-description-table-body">
              <td className="asic-description-table-body-first">AM1U1224</td>
              <th>22</th>
              <th className="asic-description-table-body-smallF">1.71V to 5.5V</th>
              <th>4</th>
              <th>3x3</th>
              <th>8</th>
              <th>26</th>
              <th>15</th>
              <th>1</th>
              <th>1</th>
              <th>4</th>
              <th className="asic-description-table-body-smallF">25kHz/ 2MHz 25MHz</th>
              <th>1</th>
              <th>1</th>
              <th>1</th>
              <th>2</th>
              <th><Link to={'#'}>Coming soon</Link></th>
            </tr>
            <tr className="asic-description-table-body">
              <td className="asic-description-table-body-first">AM1U1412</td>
              <th>10</th>
              <th className="asic-description-table-body-smallF">1.71V to 5.5V</th>
              <th>2</th>
              <th>1.6x1.6</th>
              <th>4</th>
              <th>16</th>
              <th>8</th>
              <th>1</th>
              <th>1</th>
              <th>-</th>
              <th className="asic-description-table-body-smallF">25kHz/ 2MHz</th>
              <th>-</th>
              <th>-</th>
              <th>1</th>
              <th>1</th>
              <th><Link to={'#'}>Coming soon</Link></th>
            </tr>
          </table>
        </p>
      </div>
    </div>
  </>);
};

export default Asic;
