import React from 'react';
import TextField from '@material-ui/core/TextField';

import { reset } from '../../slices/forgotSlice'

import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import { toastError, toastSuccess } from '../../components/Toasts';
import { postForgotSend } from '../../slices/forgotSlice';
import "./index.scss";
import EmailRedirector from '../../components/checkMail';


const ForgotPage = () => {

    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const [error, setError] = React.useState(null);
    const forgotDataSend = useSelector((state) => state.forgotSend);
    const [emailSend, setEmailSend] = React.useState(false);
    const [emailuser, setEmailuser] = React.useState("");
    const onSubmit = (data, e) => {
        setEmailuser(data.email)
        dispatch(postForgotSend(data));
    };

    useEffect(() => {
        if (forgotDataSend?.loading === "idle") {
            const err = forgotDataSend?.error?.error?.message
            if (err) {
                setError(err)
            }
        }

    }, [forgotDataSend.loading])

    useEffect(() => {
        if (error && forgotDataSend.loading === "idle") {
            toastError(error)
        }
        reset()
    }, [error, forgotDataSend.loading])


    useEffect(() => {
        if (forgotDataSend.loading === "idle" && forgotDataSend.data?.ok) {
            toastSuccess('Password reset email sent')
            setEmailSend(true)
        }
        reset()
    }, [forgotDataSend])

    const onError = (errors, e) => console.log(errors, e);

    return (
        <div className="forgot">
            <div className="forgot-container">
                <div className="forgot-container-loginForm">
                    {!emailSend ? <><h1>Forgot password</h1>
                        <div className="contact-form-inputs">
                            <form onSubmit={handleSubmit(onSubmit, onError)}>
                                <div className="contact-form-inputs-main">
                                    <TextField required
                                        label="Email"
                                        margin="normal"
                                        variant="filled"
                                        {...register("email")}

                                    />
                                    <button className="globalBtn forgot-container-loginForm-btn" >Send email</button>
                                    <br />
                                </div>
                            </form>
                        </div>
                    </> : <>
                        <h1>Email sent</h1>
                        
                        <div className='forgot-container-loginForm-ok'>
                        <EmailRedirector emailAddress={emailuser} />
                        </div>
                    </>}
                </div>

            </div>
        </div>
    );
};

export default ForgotPage;
