import './index.scss';
import wordlAbout from '../../assets/images/worldAbout.png';
import UkraineImg from '../../assets/images/Ukraine.png';
import NevadaImg from '../../assets/images/Nevada.png';
import ArmeniaImg from '../../assets/images/Armenia.png';
import MapAboutBlockItem from './mapAboutBlockItem';
import { Fade } from "react-reveal";

const MapAbout = () => {
	return (
		<>
			<div className='map'>
				<div className='map-about'>
					<Fade>
					<div className='map-about-header'>Our Locations</div>
					</Fade>
					<Fade>
					<div className='map-block'>
						<img src={wordlAbout} alt='Our Locations Map' />
						<MapAboutBlockItem
							location='USA, Milpitas'
							products='Marketing, Applications, Sales'
							posY={546}
							posX={203}
						/>
						<MapAboutBlockItem
							location='Ukraine, Lviv'
							products='Applications, Hardware, Marketing, Sales'
							posY={716}
							posX={1003}
							img={UkraineImg}
						/>

						<MapAboutBlockItem
							location='HQ Reno, Nevada'
							products='Operations, Hardware, Applications, Marketing, Sales'
							posY={636}
							posX={253}
							img={NevadaImg}
						/>
						<MapAboutBlockItem
							location='Brazil'
							products='R&D'
							posY={325}
							posX={485}
						/>
						<MapAboutBlockItem
							location='Armenia'
							products='R&D'
							posY={600}
							posX={1103}
							img={ArmeniaImg}
						/>
						<MapAboutBlockItem
							location='Turkey'
							products='Operations'
							posY={580}
							posX={895}
						/>
						<MapAboutBlockItem
							location='Taiwan'
							products='Operations'
							posY={540}
							posX={1385}
							textAlign='right'
						/>

					</div>
					</Fade>
				</div>
			</div>
		</>
	);
};

export default MapAbout;
