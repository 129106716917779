import { Player, ControlBar } from 'video-react';
//import "./index.scss";

const VideoPlayer = (props) => {
    return (<div className='video-box'>
        <Player muted autoPlay controls={false} poster={props.poster}>
            <ControlBar disableCompletely={true} />
            <source src={props.src} />
        </Player>
    </div>);
};

export default VideoPlayer;
