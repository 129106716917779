import { useState , useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Toggle from "react-toggle";
import axios from "axios";
import TextField from '@material-ui/core/TextField';
import { useForm } from "react-hook-form";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import { API } from "../../const";
import useAnalyticsEventTracker from '../../google';
import { useNavigate } from 'react-router-dom';
import west from "../../assets/images/logo-in-png-format.png"
import cedar from "../../assets/images/logo_cedar.png"
import epc from "../../assets/images/epc.png"
import logo_mrb from "../../assets/images/logo_mrb.png"
import { Fade } from "react-reveal";
import "./index.scss"
import SEO from "../../components/seo";
import { useAuthContext } from "../../context/auth";

const Contact = ({ setContact, className, setShowNav }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const gaEventTracker = useAnalyticsEventTracker('Use contact');
  const [showVideo, setShowVideo] = useState(false);
  const [useUser, setUseUser] = useState(false);
  const [formSuccess, setSuccess] = useState(false);
  const [selectedDate, handleDateChange] = useState(new Date());
  const { user } = useAuthContext();
  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  const history = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    gaEventTracker('Submit contact form')
    const contactData = {
      dateStart: selectedDate,
      ...data
    }
    axios.post(API, contactData)
      .then(function (response) {
        if (response.status === 201) {
          setSuccess(true)
          sleep(4000).then(() => {
            setShowNav(false)
            setContact(false)
            setSuccess(false)
          })


        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const onError = (errors, e) => console.log(errors, e);

  let mobile_class = showVideo ? "contact-page-p-desk-off" : "contact-page-p-desk-on";
  if (formSuccess) {
    mobile_class = "contact-page-p-desk-on";
  }

  return (<>
  <SEO title={"Contact - Programmable Mixed-Signal µASICs, DC/DC modules, Power delivery | Atlas Magnetics"}  description={"Atlas Magnetics produces low cost programmable mixed-signal µASICs, thin DC/DC converters, PMIC, load switch IC for various applications. Contact us for custom IC solutions."} type={"page"} keywords={"Atlas Magnetics, Inductors, IC, semiconductors, power delivery IC, mixed signal IC, programmable IC, ASIC, µASICs, micro Asic, FPGA"} />
    <div className="contact">
      <div className="contact-header">
        <FontAwesomeIcon onClick={() => { setContact(false); gaEventTracker('Close contact form') }} icon={faXmark} />
      </div>
      {formSuccess ? <div className="contact-status-box">
        <Fade>
          <div className="contact-status-box-text">
            <img src="https://cdn.atlasmagnetics.com/success.svg" alt="" />
            <p>Thanks for your request!
              <p>We will contact you within 4 hours.</p></p>
          </div>
        </Fade>
      </div> : <Fade><form className="contact-form" onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="logic" onClick={() => history(-1)} > <p>Guaranteed 4 hour response M-F</p><FontAwesomeIcon icon={faXmark} /></div>
        <div className="contact-form-inputs">
          <div className="contact-form-inputs-main">
            <TextField required
              label="Your Name"
              margin="normal"
              
              disabled={useUser}
              variant="filled"
              {...register("name")}
            />
            <TextField required
              label="Subject"
              margin="normal"
              variant="filled"
              {...register("subject")}

            />
            <TextField required
              label="Your Email"
              margin="normal"
              variant="filled"
              type="email"
              className="email-input"
              {...register("email")}
            />
          </div>
          <div className="contact-form-inputs-textarea">
            <TextField required
              label="Your Message"
              margin="normal"
              variant="filled"
              multiline
              minRows={10}
              {...register("body")}
            />
          </div>
        </div>
        <div className="contact-form-footer">
          <div className="contact-form-toggle">
            <Toggle
              checked={showVideo}
              onChange={() => setShowVideo(!showVideo)}
            />
            <span>I'd like to receive a video call</span>
          </div>
          {false ? <><div className="contact-form-toggle">
            <Toggle
              checked={useUser}
              onChange={() => setUseUser(!useUser)}
            />
            <span>Use profile data</span>
          </div></> : null}
          <button className="contact-form-submit" type="submit">
            Submit
          </button>
        </div>
        {showVideo && (
          <div className="contact-form-video">
            <div className="contact-form-video-date">
              <TextField required
                label="Enter Your Email"
                margin="normal"
                variant="filled"
                type="email"
                {...register("emailTeams")}
              />
              <div className="contact-form-video-date-pick">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker required
                    label="Select date"
                    margin="normal"
                    variant="filled"
                    value={selectedDate}
                    minDate={new Date()}
                    onChange={handleDateChange}
                    renderInput={(params) => <TextField {...params} {...register("dateStart")} />}

                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
        )}

      </form></Fade>}

    </div>
    <div className={`contact-page ${mobile_class}`}>
      <div className="contact-page-header">
        <h2>Regional Distributors</h2>
      </div>
      <div className="emea">EMEA</div>

      <div className="contact-page-description">

        <div className="first">
          <div className="contact-page-description-contact-box">
            <Fade>
              <div className="box">
                <div className="epc" >
                  <img src={epc} />
                  <br /><a href="https://www.epsglobal.com/" target="_blank" rel="noreferrer">www.epsglobal.com</a>
                  <p><a className="mailLink" href="mailto:atlas@epsglobal.com">atlas@epsglobal.com</a></p>

                </div>
              </div>
            </Fade>
            <div className="col">
              <Fade>
                <div className="box">
                  <h4 className="padding">DUBLIN, IRELAND (HQ)</h4>
                  Unit 17 Kinsealy Business Park
                  <br />Kinsealy
                  <br />Co. Dublin
                  <br />Ireland
                  <br />Phone: +353 1 8038918

                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4 className="padding">DARMSTADT, GERMANY</h4>
                  Rheinstrasse 41
                  <br />64283
                  <br />Darmstadt
                  <br />Germany
                  <br />Phone: +49 6151 501 2454
                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4 className="padding">BRNO, CZECH REPUBLIC</h4>
                  Electronic Product Services Czech, s.r.o.
                  <br />Technology Park, Building B2
                  <br />Podnikatelská 2902/4
                  <br />612 00 Brno
                  <br />Czech Republic
                  <br />Phone: +420 541 215 126
                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4 className="padding">TIMISOARA, ROMANIA</h4>
                  Calea Torontalului DN 6, km 6,
                  <br />PITT parcela C1 Timisoara
                  <br />Timisoara
                  <br />Romania
                  <br />Phone: +40 256 22 33 07
                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4>READING, UNITED KINGDOM</h4>
                  14A Church Street, Caversham
                  <br />Reading
                  <br />RG4 8AU
                  <br />United Kingdom
                  <br />Phone: +44 (0)20 3008 9709
                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4 className="padding">WARSAW, POLAND</h4>
                  Ul. Polna 48/31
                  <br />00-644, Warszawa
                  <br />Poland
                  <br />Phone: +48 22 825 10 92
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-page-header">
        <h2>Sales Representatives</h2>
      </div>
      <div className="contact-page-description">
        <div className="last-block">
          <div className="contact-page-description-contact-box">
            <Fade>
              <div className="box">
                <div className="cedar">
                  <img src={cedar} />
                  <br /><a href="http://www.cedartech.co.uk/" target="_blank" rel="noreferrer">www.cedartech.co.uk</a>
                  <p><a className="mailLink" href="mailto:info@cedartech.co.uk">info@cedartech.co.uk</a></p>
                </div>
              </div>
            </Fade>
            <div className="col">
              <Fade>
                <div className="box">
                  <h4>United Kingdom:</h4>
                  <p><b>Paul Richardson</b>
                    <br />Email: paul@cedartech.co.uk
                    <br />Tel: +44 7747 865040
                    <br />
                    <br /><b>Sean Lynch</b>
                    <br />Email: sean@cedartech.co.uk
                    <br />Tel: +44 7775 923518</p>
                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4>Sweden:</h4>
                  <p><b>Michel Olsson</b>
                    <br />Email: Michel@cedartech.se
                    <br />Tel: +46 70 026 2330
                    <br />
                    <br /><b>Bo Molander</b>
                    <br />Email: Bo@cedartech.se
                    <br />Tel: +46 73 708 3570</p>
                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4>Finland and Baltics:</h4>
                  <p><b>Mika Rakkolainen</b>
                    <br />Email: mika@cedartech.co.uk
                    <br />Tel: +358 503308400</p>
                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4>Norway:</h4>
                  <p><b>Brian Märcher</b>
                    <br />Email: brian@cedartech.dk
                    <br />Tel: +45 30527018</p>
                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4>Poland:</h4>
                  <p><b>Piotr Wloczewski</b>
                    <br />Email: piotr@cedartech.pl
                    <br />Tel: +48 605 308 216</p>
                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4>Denmark:</h4>
                  <p><b>Morten Møller</b>
                    <br />Email: morten@cedartech.dk
                    <br />Tel: +45 53600898</p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-page-description">

        <div className="second-block">
          <div className="contact-page-description-contact-box">
            <Fade>
              <div className="box">
                <div className="west" >
                  <img src={west} />
                  <br /><a href="https://www.westmarkco.com/" target="_blank" rel="noreferrer">www.westmarkco.com</a>
                  <p><a className="mailLink" href="mailto:sales@westmarkco.com">sales@westmarkco.com</a></p>
                </div>
              </div>
            </Fade>

            <div className="col">
              <Fade>
                <div className="box">
                  <h4>Washington Office - HQ:</h4>
                  <p>
                  1750 - 112th Avenue NE Suite C225 Bellevue, WA 98004
                  <br />Phone: 425-454-1944
                  <br />Territories: Washington, Northern Idaho, Alaska, Montana
                  </p>
                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4>Oregon Office:</h4>
                  <p>
                  8705 SW Nimbus Ave., Suite 320 Beaverton, OR 97008 
                  <br />Phone (503) 641-8780
                  <br />Territories: Oregon, Southern Idaho, SW Washington (Vancouver, WA)
                  </p>
                </div>
              </Fade>
              <Fade>
                <div className="box">
                  <h4>British Columbia Office:</h4>
                  <p>
                  5-7488 Mulberry Place Burnaby, BC Canada V3N 5B4 
                  <br />Phone: (778) 929-2858
                  <br />Territories: British Columbia, Alberta, Manitoba, & Saskatchewan
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-page-description">

        <div className="second-block">
          <div className="contact-page-description-contact-box">
            <Fade>
              <div className="box">
                <div className="west" >
                  <img src={logo_mrb} />
                  <br /><a href="https://mrbsales.net/" target="_blank" rel="noreferrer">www.mrbsales.net</a>
                  <p><a className="mailLink" href="mailto:info@mrbsales.net">info@mrbsales.net</a></p>
                </div>
              </div>
            </Fade>

            <div className="col">
              <Fade>
                <div className="box">
                  <h4>Regions:</h4>
                  <p>
                  Texas, Oklahoma, Louisiana, Arkansas, Kansas, Country of Mexico
                  <br />Phone: 972-250-2900
                  <br /> 
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
};

export default Contact;
