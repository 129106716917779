import { useEffect } from "react";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import SEO from "../../components/seo";
import win_download from "../../assets/icons/windows_icon.svg"
import mac_download from "../../assets/icons/apple_icon.svg"
import guides from "../../assets/icons/download.svg"
import VideoLoder from "../../components/VideoLoader";
import "./index.scss";

const Edea = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (<>
        <SEO title={"Configurable µASIC - Mixed Signal Programmable Micro ASICs (µASICs) | Atlas Magnetics"} description={"Atlas Magnetics offers flexible programmable micro ASICs (µASICs)  that can replace several discrete ICs. Our micro ASICs are super low power and low cost, perfect for any application requiring mixed signal capabilities"} type={"page"} keywords={"Programmable ASICs, Mixed signal ASICs, µASIC,  microASIC, micro asic, uASIC, low power programmable IC, low cost programmable IC, PWM generator, Glue logic, Power Sequencer,Power on Reset, POR IC, LED controller IC, Voltage monitoring, I2C, State Machine, Wake and sleep generator, EDA for ASICs, Replace FPGA, Cheaper than FPGA"} />


        <div className="edea">
            <div className="edea-header">
            <VideoLoder src="https://cdn.atlasmagnetics.com/video/AtlasEDA_Banner.mp4" poster="https://cdn.atlasmagnetics.com/AtlasEDA_Black.png" />
            </div>
            <div className="edea-description">
                <Fade>
                    <h4>Empowering You to Make Your Own ASIC</h4>
                </Fade>

                <p>
                    <Fade>
                        Atlas EDA software, a powerful and intuitive tool that allows you to easily create your own custom μASIC. Whether you're an experienced designer or just starting out, Atlas EDA provides the necessary features and functionality to bring your ideas to life.




                    </Fade>
                    <br />
                    <Fade>
                        Developed by Atlas Magnetics, Atlas EDA serves as a comprehensive solution for configuring
                        <ul className="edea-description-list">
                            <li>- µASICs;</li>
                            <li>- AM Power Modules;</li>
                            <li>- third party products.</li>
                        </ul>
                        
                        
                        
                    </Fade>
                    <br />
                    <Fade>
                        Additionally, Atlas EDA supports system-level design, enabling you to create multi-chip solutions. This flexibility allows you to incorporate other μASICs, AM power modules, and even Third-Party Products into your designs. By leveraging the capabilities of Atlas EDA, you can explore innovative possibilities and integrate various components to achieve your desired system-level functionality.
                    </Fade>
                    <br />
                </p>
            </div>
            <div className="edea-images">
                <img src="https://cdn.atlasmagnetics.com/software.png" alt="software" />
                <div className="edea-images-text">
                    <Fade>
                        <h4>Software Tools - Atlas EDA</h4>
                    </Fade>
                    <img src="https://cdn.atlasmagnetics.com/software.png" alt="software" />
                    <Fade>
                        <p>
                        <br />
                            Make your own ASIC with easy schematic-capture tools
                            <br />
                            <br />
                            The Atlas EDA software is a free to use, free to download software used to Configure all µASIC and configurable DC/DC products from Atlas Magnetics.

                            <Link className="globalBtn edea-description-btnDownload" alt="" to={'/prepare?download=win'}> Download Atlas EDA for Windows <img src={win_download} alt=""/></Link>
                            <Link className="globalBtn edea-description-btnDownload" alt="" to={'/prepare?download=mac'}> Download Atlas EDA for Mac OS<img src={mac_download} alt=""/></Link>
                            <Link className="globalBtn edea-description-btnDownload" alt="" to={'/prepare?download=guide'}> Download Atlas EDA user guide<img src={guides} alt=""/></Link>
                        </p>
                    </Fade>
                </div>
            </div>
            <div className="edea-supply">
                <div className="edea-supply-text">
                    <Fade>
                        <h4>Hardware Tools - Ferro Board</h4>
                    </Fade>
                    <img src="https://cdn.atlasmagnetics.com/hardware.png" alt="hardware" />

                    <p>
                        <Fade>
                            Bring your design prototype to life with intuitive evaluation boards
                        </Fade>
                        <br />
                        <Fade>
                            µASIC Ferro Board—an all-in-one solution for configuring, testing, and programming µASIC parts. With seamless USB-C connectivity to your computer and integration with the powerful Atlas EDA software, you can confidently evaluate and validate your custom designs. Conveniently placed probe points on the board facilitate easy design verification, while the Atlas EDA software generates realistic user behavior signals.
                        </Fade>
                        <br />
                    </p>
                    <Link className="globalBtn edea-description-getContact" alt="" to={'/contact'}> Contact us to get your Ferro Board</Link>
                </div>
                <img src="https://cdn.atlasmagnetics.com/hardware.png" alt="hardware" />
            </div>
        </div>
    </>);
};

export default Edea;