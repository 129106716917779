import "./index.scss";

const NotFound = () => {
  return (
    <div className="page-404">
        <div className="page-404-text">
        Not Found
        <p>404</p>
    </div>
    </div>
  );
};

export default NotFound;
