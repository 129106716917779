import { useEffect } from "react";
import { Fade } from "react-reveal";
import SEO from "../../components/seo";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import "./index.scss";

const Power = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (<>
    <SEO title={"Atlas Magnetics - Low Cost Power Delivery ICs with Integrated Inductors"} description={"Discover our new range of power delivery ICs, featuring brand new super small integrated inductors, PMIC, load switch ICs, and discrete super small inductors. Our low cost DC/DC modules offer efficient power management solutions for your next project."} type={"page"} keywords={"power management IC, Load Switch IC, DC/DC converters, PMIC, DC/DC modules, Inductor, DC/DC with integrated inductor, Integrated inductor, Magnetics, Smallest DC/DC converter, Smallest inductor, Low cost inductor, Low cost DC/DC module."} />
    <div className="power">
      <div className="power-header">
        <Fade>
          <h2>Power Delivery</h2>
        </Fade>
      </div>
      <ul className="power-left-menu">
        <li><AnchorLink href="#overview" offset={240} className="globalLink">Owerview</AnchorLink></li>
        <li><AnchorLink href="#modules" offset={360} className="globalLink">DC/DC modules</AnchorLink></li>
        <li><AnchorLink href="#switches" offset={180} className="globalLink">Load switches</AnchorLink></li>
      </ul>
      <div className="power-supply">
        <div className="power-supply-text">
          <Fade>
            <h4>DC/DC Modules without Premium Pricing</h4>
          </Fade>

          <img src="https://cdn.atlasmagnetics.com/dcmodule.png" alt="dcmodule" />
          <p id="overview">
            <Fade>
              For over a decade semiconductor companies have encapsulated DC/DC
              silicon with inductors for space-constrained applications. This
              solution has a hefty price: modules typically cost twice or more
              than discrete solutions.
            </Fade>
            <br />
            <Fade>
              Atlas Magnetics is reinventing the way modules are built to be truly
              cost-competitive to discrete solutions. Our inductors are built
              within the package substrate using proprietary processes and
              material compositions, with three key benefits:
            </Fade>
            <br />
            <Fade>
              Cost is significantly reduced compared to use of third-party
              inductors
            </Fade>
            <br />
            <Fade>
              Supply capacity isn’t dependent on third-party companies

              <br />
              Height and package area are reduced. No need to support multiple,
              independent components
            </Fade>
          </p>
        </div>
        <img src="https://cdn.atlasmagnetics.com/dcmodule.png" alt="dcmodule" />
      </div>
      <div className="power-images">
        <img src="https://cdn.atlasmagnetics.com/ammodule.png" alt="ammodule" />
        <div className="power-images-text">
          <Fade>
            <h4>AM Module Technology</h4>
          </Fade>
          <img src="https://cdn.atlasmagnetics.com/ammodule.png" alt="ammodule" />
          <p id="modules">
            <Fade>
              Atlas Magnetics modules don’t rely on third-party inductors.
              Instead, we build our inductors within the package substrate using
              proprietary materials technology. Using special process techniques,
              AM creates a multi-layer magnetic core as shown on the left. With
              this laminate structure the resulting inductor reduces the effect of
              eddy currents and other parasitic effects that adversely impact
              small inductors.
            </Fade>
          </p>
        </div>
      </div>
      <div className="power-supply">

        <div className="power-supply-text">
          <Fade>
            <h4>Upcoming DC/DC Products</h4>
          </Fade>
          <Fade>
            <img src="https://cdn.atlasmagnetics.com/dcupcoming.png" alt="dcupcoming" />
          </Fade>
          <p id="switches">
            <Fade>
              In Q1 2024 Atlas Magnetics is launching the first products in
              the DC/DC module portfolio, ranging from 300mA to 1.5A total output
              current, 1 to 3 output channels, all of which can include integrated
              inductors. This converter portfolio will feature:
            </Fade>
            <br />
            <Fade>
              1. Single power rail DC/DC modules with package height of 0.65mm in
              packages as small as 1.2 x 1.2 mm (including integrated magnetics).
            </Fade>
            <br />
            <Fade>
              2. Three-channel output power rail DC/DC modules with package height
              of 0.65mm in packages as small as 2.0 x 1.8 mm (including integrated
              magnetics).
            </Fade>
            <br />
            <Fade>
              From there, AM will supplement products to include higher current,
              more features, and customer-specific requests and recommendations.
            </Fade>
            <br />
            <Fade>
              Note: DC/DC ICs may also be purchased without integrated inductors
              for pin-to-pin compatibility with competitive products.
            </Fade>
          </p>
        </div>
        <img src="https://cdn.atlasmagnetics.com/dcupcoming.png" alt="dcupcoming" />
      </div>
      <div className="power-supply-last" />
    </div>
  </>);
};

export default Power;
