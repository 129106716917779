import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import logout from "../../assets/icons/profile/logout.svg";
import "./index.scss"
import { useAuthContext } from '../../context/auth';

export default function LogOutComponent() {
    const { logOut } = useAuthContext()
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleLogOut = () => {
    logOut();
    setOpen(false);
  }

  return (
    <div>

      <li className="logout" onClick={handleClickOpen}>
              <img src={logout} alt="" />
              <div className="profile-container-left-list-text">Log Out</div>
            </li>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Log Out"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="alert-dialog-flex">
        <button onClick={handleClose} className="globalBtn close-btn">
            Close
          </button>
          <button onClick={handleLogOut} className="globalBtn logout-btn" >
            Log Out
          </button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}