import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';

export const postLogin = createAsyncThunk('atlas/login', async (props, { rejectWithValue }) => {
    try {
        const response = await api.post(`/auth/local`, props);
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

const initialState = {
    data: {},
    loading: 'idle',
    error: null
}

export const login = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(postLogin.pending, (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending';
                state.error = null;
            }
        });
        builder.addCase(postLogin.fulfilled, (state, action) => {
            if (state.loading === 'pending') {
                state.data = action.payload;
                state.loading = 'idle';
                state.error = null;
            }
        });
        builder.addCase(postLogin.rejected, (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
                state.error = action.payload;
            }
        });
    }
});
export const { reset } = login.actions;
export default login.reducer;
