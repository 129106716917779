import { useState, useEffect } from "react";
import { useWindow } from "../../useMobile";
import logo from "../../assets/images/logo.svg";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import hamburger from "../../assets/images/hamburger.svg";
import closebtn from "../../assets/images/closebtn.svg";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import avatar from "../../assets/icons/profile/avatar.svg";
import Avatar from '@material-ui/core/Avatar';
import "./index.scss";
import { useAuthContext } from "../../context/auth";



const Header = () => {
  const [dropdown, setDropdown] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [contact, setContact] = useState(false);
  const { user } = useAuthContext();
  const navigation = useNavigate();
  const header = useWindow();


  useEffect(() => {
    setShowNav(false)
    setContact(false)
    setDropdown(false)
    clearAllBodyScrollLocks(document)
  }, [navigation])

  useEffect(() => {
    setContact(false)
    document.querySelector("html")
    if (showNav) {

      document.body.style.position = "fixed"
    }
    else {
      document.body.style.position = "relative"
    }
    
  }, [showNav, navigation])

  header && contact ? disableBodyScroll(document) : enableBodyScroll(document)


  return header ? (<>
    <div className="header">

      <Link to={"/"}>
        <img src={logo} alt="logo" />
      </Link>

      <div className="header-links">

        <NavLink to="/">Home</NavLink>
        <div>
          <span onClick={() => setDropdown(!dropdown)}>
            Products <FontAwesomeIcon icon={faAngleDown} />
          </span>

          {dropdown && (
            <div className="header-links-dropdown">
              <NavLink to="/asic" onClick={() => setDropdown(false)}>
                Configurable µASIC
              </NavLink>
              <NavLink to="/power-delivery" onClick={() => setDropdown(false)}>
                Power Delivery
              </NavLink>
              <NavLink to="/services" onClick={() => setDropdown(false)}>
                Custom Services
              </NavLink>
              <NavLink to="/edea" onClick={() => setDropdown(false)}>
                Atlas EDA
              </NavLink>
            </div>
          )}
        </div>
        <NavLink to="/quality">Quality</NavLink>
        <NavLink to="/about">About AM</NavLink>
        
      </div>
      {user ? <NavLink to="/profile" className="header-profile"><Avatar alt="Remy Sharp" src={avatar} /></NavLink> : <NavLink to="/sign-in" className="globalBtn header-login-btn" >Log in</NavLink>}
      <NavLink to="/contact" className="header-contact">Contact Us</NavLink>

    </div>
    
  </>
  ) : (
    <div className="header-mobile">

      <div className="header-mobile-top" >
        <Link to={"/"}>
          <img src={logo} alt="logo" />
        </Link>
        {showNav ? (
          <img
            onClick={() => setShowNav(false)}
            src={closebtn}
            alt="hamburger"
          />
        ) : (
          <img
            onClick={() => setShowNav(true)}
            src={hamburger}
            alt="hamburger"
          />
        )}
      </div>
      {showNav && (
        <>
        
        <div className={`header-mobile-nav ${showNav && "active-nav"}`}>
        
          <NavLink to="/">Home</NavLink>
          <span onClick={() => setDropdown(!dropdown) }>
            Products <FontAwesomeIcon icon={faAngleDown} />
          </span>
          
          {dropdown && (
            <div className="header-mobile-nav-dropdown">
              
              <NavLink to="/asic" onClick={() => setDropdown(false)}>
                Configurable µASIC
              </NavLink>
              <NavLink to="/power-delivery" onClick={() => setDropdown(false)}>
                Power Delivery
              </NavLink>
              <NavLink to="/services" onClick={() => setDropdown(false)}>
                Custom Services
              </NavLink>
              <NavLink to="/edea" onClick={() => setDropdown(false)}>
                Atlas EDA
              </NavLink>
            </div>
          )}
          <NavLink to="/quality">Quality</NavLink>
          <NavLink to="/about">About AM</NavLink>
          {user ? <NavLink to="/profile">Profile</NavLink> : <NavLink to="/sign-in">Sign in</NavLink>}
          <NavLink to="/contact" className="header-contact">Contact Us</NavLink>
            

        </div>
        </>)}
    </div>
  );
};

export default Header;
