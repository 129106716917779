import { useEffect } from "react";
import { Fade } from "react-reveal";
import MapQuality from "../../components/maps/mapQuality";
import SEO from "../../components/seo";
import { useWindow } from "../../useMobile";
import "./index.scss";

const Quality = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const header = useWindow();
  return (<>
    <SEO title={"Quality & Supply - Programmable Mixed-Signal µASICs, DC/DC modules, Power delivery | Atlas Magnetics"} description={"Atlas Magnetics produces low cost programmable mixed-signal µASICs, thin DC/DC converters, PMIC, load switch IC for various applications. Contact us for custom IC solutions."} type={"page"} keywords={"Atlas Magnetics, Inductors, IC, semiconductors, power delivery IC, mixed signal IC, programmable IC, ASIC, µASICs, micro Asic, FPGA"} />

    <div className="quality">
      <div className="quality-header">
        <Fade>
          <h2>Quality & Supply</h2>
        </Fade>
      </div>
      <div className="quality-description">
        <Fade>
          <h4>Atlas Magnetics’ Commitment to Quality</h4>
        </Fade>
        <Fade>
          <p className="quality-description-first">
            Producing and delivering quality products is of the utmost importance
            to every group within Atlas Magnetics. Our teams collaborate closely
            to ensure that every product is produced beyond the industry standards
            of quality. Through avid testing, characterization, and diligent
            decision-making in the product development process Atlas Magnetics
            maintains a strong hold on all fundamentals that define a high-quality
            product.
          </p>
        </Fade>
        <Fade>
          <h4>Data Collection Beyond Competitive Benchmarks</h4>
        </Fade>
        <Fade>
          <p>
            No amount of data will ever fully capture the real performance of any
            device. In recognizing this fact, Atlas Magnetics goes beyond
            competitor benchmarks of quality assurance by examining more
            datapoints and collecting more data, thereby taking the truest
            approach to ensuring your part operates as expected. All products are
            tested across corners and Gigabytes or more of data are collected for
            even the simplest IC.
          </p>
        </Fade>
      </div>
      <div className="quality-images">
        <img src="https://cdn.atlasmagnetics.com/qualityImages.png" alt="quality" />
        <div className="quality-images-text">
          <Fade>
            <h4>Minimizing Risk Variables</h4>
          </Fade>
          <img src="https://cdn.atlasmagnetics.com/qualityImages.png" alt="quality" />

          <p>
            <Fade>
              Modern semiconductor companies often approach multiple foundries,
              packaging vendors, and all other suppliers within the supply chain
              to be able to negotiate a better cost. However, no two foundries,
              package suppliers, or processes are the same. This cost-cutting
              approach also drastically increases the dimensions for failure, and
              new products often experience unexpected failures caused by simply
              transferring designs between foundries or packaging houses. In many
              cases the outcome becomes more costly as the companies struggle to
              make up the incremental cost of transferring the IC, as well as
              incurring additional yield losses.
            </Fade>
            <br />
            <Fade>
              The Atlas Magnetics approach is adopted from the highest-quality IC
              design principles: minimize risk by tightly maintaining the supply
              chain. One process, limited package variety, and a standardized
              testing flow enables new products to be validated more quickly and
              with better quality.
            </Fade>
          </p>
        </div>
      </div>
      <div className="quality-supply">
        <div className="quality-supply-text">
          <Fade>
            <h4>Supply</h4>
          </Fade>
          <img src="https://cdn.atlasmagnetics.com/supply.png" alt='supply' />
          <p>
            <Fade>
              Building Integrated Magnetics within the package substrate solves an inherent issue to power modules: guaranteeing 3rd-party inductor supply. Even if silicon capacity can be assured by other power module vendors, there is still a reliance on the combination of third party inductors. This reliance can cause sudden changes to module development costs or, in worst cases such as industry capacity crunches, can become the critical path for power module supply.
            </Fade>
            <br />
            <Fade>
              The Atlas Magnetics approach creates the inductor within the package substrate, so any limitation of inductor supply is due to the substrate, not third-party component availability. Package substrates are developed on massive panels, with hundreds of thousands of units per panel. Through assured capacity agreements and elimination of third-party variables AM is able to assure supply up to hundreds of millions of units and beyond at a degree completely unprecedented within the power module industry.
            </Fade>
          </p>
        </div>
        <img src="https://cdn.atlasmagnetics.com/supply.png" alt='supply' />
      </div>
      {false ? <MapQuality/> : null}
    </div>
    <div className="quality-supply">
        <div className="quality-supply-cert">
          <Fade>
            <h4>Certificates</h4>
          </Fade>
          <br/>
          <br/>
          <img src="https://cdn.atlasmagnetics.com/cert.png" alt='cert' />
        </div>
        
      </div>

  </>);
};

export default Quality;
