import { useEffect, useState } from "react";

export const useWindow = () => {
    const [header, setHeader] = useState(false);
    const onMobileSize = () => {
      if (window.innerWidth < 1060) {
        setHeader(false);
      } else {
        setHeader(true);
      }
    };
  
    useEffect(() => {
      window.addEventListener("resize", onMobileSize);
      onMobileSize();
      return () => window.removeEventListener("resize", onMobileSize);
    }, []);
  
    return header;
  };