import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getToken } from '../../auth';
import { BEARER } from '../../const';

export const getApiUser = createAsyncThunk('atlas/user', async (props, thunkAPI) => {
    try {
        api.defaults.headers.Authorization = `${BEARER} ${getToken()}`
        const response = await api.get(`/users/me`, props);
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
    }
});

const initialState = {
    data: undefined,
    loading: 'idle',
    error: null,
    done: false
    
}

export const user = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        reset: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(getApiUser.pending, (state, action) => {
            if (state.loading === 'idle') {
                state.loading = 'pending';
                state.error = null;
                state.done = false;
            }
        });
        builder.addCase(getApiUser.fulfilled, (state, action) => {
            if (state.loading === 'pending') {
                state.data = action.payload;
                state.loading = 'idle';
                state.error = null;
                state.done = true;
            }
        });
        builder.addCase(getApiUser.rejected, (state, action) => {
            if (state.loading === 'pending') {
                state.loading = 'idle';
                state.error = action.payload;
                state.done = false;
            }
        });
    }
});
export const { reset } = user.actions;
export default user.reducer;
