import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';
import { reset } from '../../slices/loginSlice'
import BenefitsComponent from '../../components/Benefits';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';


import { postLogin } from '../../slices/loginSlice';
import { setToken } from '../../auth';
import { useAuthContext } from '../../context/auth';
import { useEffect } from 'react';
import { toastError } from '../../components/Toasts';
import "./index.scss";
import { Fade } from 'react-reveal';

const LoginPage = () => {
    const { setUser, user } = useAuthContext();
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const [showPassword, setShowPassword] = React.useState(false);
    const [error, setError] = React.useState(null);
    const loginData = useSelector((state) => state.login);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const navigator = useNavigate();
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const onSubmit = (data, e) => {

        const user = {
            ...data,
            username: data?.email
        }
        dispatch(postLogin(user));

    };

    useEffect(() => {
        if(loginData?.loading === "idle"){
            const err = loginData?.error?.error?.message
            if (err) {
                setError(err)
                dispatch(reset())
            } else {
                if (loginData?.data?.user?.username){
                    setUser(loginData?.data?.user)
                    setToken(loginData?.data?.jwt)
                    dispatch(reset())
                }

            }
        }
        
    },[loginData.loading])

    useEffect(() => {
        if(error && loginData.loading === "idle") {
            toastError(error)
        }
    },[error, loginData.loading])

    const onError = (errors, e) => console.log(errors, e);

    useEffect(() => {

        window.scrollTo(0, 0)
      }, [])
      useEffect(() => {
        if (user){
            navigator('/profile');
        }
    }, [user])
    return (
        <div className="login">
            <Fade>
            <div className="login-container">
                <div className="login-container-loginForm">
                    <h1>Log in</h1>
                    <div className="contact-form-inputs">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="contact-form-inputs-main">
                            <TextField required
                                label="Email"
                                margin="normal"
                                variant="filled"
                                {...register("identifier")}

                            />
                            <TextField required
                                label="Password"
                                margin="normal"
                                variant="filled"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{endAdornment: (
                                    <InputAdornment position='end' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                        
                                      <FontAwesomeIcon icon={!showPassword ? faEyeSlash : faEye} className='login-container-loginForm-passEye'/>
                                    
                                    </InputAdornment>
                                )}}
                                {...register("password")}

                            />
                            <span className='login-container-loginForm-forgot'><Link to={"/forgot-password"}>Forgot password?</Link></span>
                            <button className="globalBtn login-container-loginForm-btn" >Log in</button>
                           
                            <h4 className="login-container-loginForm-reg">Don`t have an account? <Link to={'/sign-up'}><span>Register now</span></Link></h4>
                            <p className="login-container-loginForm-help">Need help logging in?</p>
                            <h5>By logging in, you agree to AM`s <Link className="regLogin-login" to={"/policy"}>privacy policy and terms of use.</Link></h5>
                        </div>
                        </form>
                    </div>
                </div>
                <BenefitsComponent text="Your Benefits" />
            </div>
            </Fade>
        </div>
    );
};

export default LoginPage;
