import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import "./index.scss";
const EmailPage = () => {
    return (
        <div className="email">
            <Fade>
            <div className="email-container">
                <div className="email-container-text">
                    <h1>Thank you!</h1>
                    <p>Thanks for signing up! An email has been sent to you with link with confirmation.</p>
                    <h5>By logging in, you agree to AM`s <Link className="regLogin-login" to={"/policy"}>privacy policy and terms of use.</Link></h5>
                    
                </div>
            </div>
            </Fade>
        </div>
    );
};

export default EmailPage;
