import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import "./index.scss";


const RegLoginPage = () => {

    const [searchParams] = useSearchParams();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      

    const dataLink = searchParams.get('download') ? `?download=${searchParams.get('download')}` : null;
    return (
        <div className="regLogin">
            <div className="regLogin-container">
                <div className="regLogin-container-text">
                    <h1>Welcome to Atlas Magnetics!</h1>
                    <p>To download Atlas EDA Please complete the registration. <Link className="globalBtn regLogin-btnRegister" to={`/sign-up${dataLink}`}>Registration</Link> </p>
                    <h4>Already have an account? <Link className="regLogin-login" to={`/sign-in${dataLink}`}>Login</Link> here</h4>
                    <h5>By logging in, you agree to AM`s <Link className="regLogin-login" to={"/policy"}>privacy policy and terms of use.</Link></h5>
                </div>
            </div>
        </div>
    );
};

export default RegLoginPage;
