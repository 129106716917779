import { combineReducers } from 'redux';
import register from '../../slices/registerSlice';
import login from '../../slices/loginSlice';
import user from '../../slices/userSlice';
import forgotSend from '../../slices/forgotSlice';
import resetSend from '../../slices/resetSlice';
import changeSend from '../../slices/changePass';

const reducers = combineReducers({
    register: register,
    login: login,
    user: user,
    forgotSend: forgotSend,
    resetSend: resetSend,
    changeSend: changeSend
});

export default reducers;
