import youtube from "../../assets/images/you.svg";
import linkedin from "../../assets/images/link.svg";
import { Link } from "react-router-dom";
import useAnalyticsEventTracker from '../../google';
import { Fade } from "react-reveal";
import "./index.scss";


const Footer = () => {
  const gaEventTracker = useAnalyticsEventTracker('Footer Actions');
  return (
    <div className="footer">
      <div className="footer-container">
        <Fade>
        <div className="footer-container-links">
          <div className="footer-container-links-products">
            <img src="https://cdn.atlasmagnetics.com/logo_white.png" alt="Logo" />
            <span>Products</span>
            <p>
              <Link to="/asic">Configurable µASIC</Link>
              <br />
              <Link to="/power-delivery">Power Delivery</Link>
              <br />
              <Link to="/services">Custom Services</Link>
              <br />
              <Link to="/edea">Atlas EDA</Link>
            </p>
          </div>
          <div className="footer-container-links-additional">
            <img src="https://cdn.atlasmagnetics.com/logo_white.png" alt="Logo" />
            <p>
              <Link to="/about">About AM</Link>
              <br />
              <Link to="/quality">Quality & Supply</Link>
              <br />
              <Link to="/policy">Privacy Policy and Terms of Use</Link>
            </p>
          </div>
        </div>
        <div className="footer-container-social">
          <a href="https://www.youtube.com/channel/UC5yrpGINzXWSuRp-XQaJNhg" onClick={()=>gaEventTracker('Click on social link')} target="_blank" rel="noreferrer">
            <img src={youtube} alt="Logo" />
          </a>
          <a href="https://www.linkedin.com/company/atlas-magnetics" onClick={()=>gaEventTracker('Click on social link')} target="_blank" rel="noreferrer">
            <img src={linkedin} alt="Logo" />
          </a>
        </div>
        </Fade>
      </div>
    </div>
  );
};

export default Footer;
