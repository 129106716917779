
import svg1 from "../../assets/icons/benef/1.svg"
import svg2 from "../../assets/icons/benef/2.svg"
import svg3 from "../../assets/icons/benef/3.svg"
import svg4 from "../../assets/icons/benef/4.svg"
import svg5 from "../../assets/icons/benef/5.svg"
import svg6 from "../../assets/icons/benef/6.svg"
import "./index.scss";
const BenefitsComponent = ({text}) => {
    return (<div className="benefits">
        <h1>{text}</h1>

        <ul className="benefits-list">
            <li><img src={svg2} alt="" /><div className="benefits-list-text">Access to EDA download and updates</div></li>
            <li><img src={svg3} alt="" /><div className="benefits-list-text">Watch or download Atlas EDA user guide</div></li>
            <li><img src={svg1} alt="" /><div className="benefits-list-text">Access to data sheets</div></li>
            <li><img src={svg5} alt="" /><div className="benefits-list-text">Samples requests</div></li>
            <li><img src={svg6} alt="" /><div className="benefits-list-text">Technical support</div></li>
            <li><img src={svg4} alt="" /><div className="benefits-list-text">New products update</div></li>
        </ul>
    </div>
    );
};

export default BenefitsComponent;
