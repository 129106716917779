import { useEffect } from "react";
import { Fade } from "react-reveal";
import SEO from "../../components/seo";
import "./index.scss";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (<>
    <SEO title={"Custom Services - Programmable Mixed-Signal µASICs, DC/DC modules, Power delivery | Atlas Magnetics"} description={"Atlas Magnetics produces low cost programmable mixed-signal µASICs, thin DC/DC converters, PMIC, load switch IC for various applications. Contact us for custom IC solutions."} type={"page"} keywords={"Atlas Magnetics, Inductors, IC, semiconductors, power delivery IC, mixed signal IC, programmable IC, ASIC, µASICs, micro Asic, FPGA"} />

    <div className="services">
      <div className="services-header">
        <Fade>
          <h2>Custom Services</h2>
        </Fade>
      </div>
      <div className="services-description">
        <Fade>
          <h4>Custom Services</h4>
        </Fade>
        <p>
          <Fade>
            Atlas Magnetics can create Custom PMICs or Custom Integrated Inductors for customers that incorporate the IP for integrated passives, advanced DC/DC architectures, and configurability
          </Fade>
        </p>
      </div>
      <div className="services-images">
        <div className="services-images-text">
          <Fade>
            <h4>Application-Specific PMICs</h4>
          </Fade>
          <p>
            <Fade>
              Contact Us above to discuss your product request.
            </Fade>
            <br />
            <Fade>
              Atlas Magnetics builds PMIC platforms using an advanced
              semiconductor process with industry-leading RDSon. PMIC output
              voltages range from 0.6V to 5.5V. Key features of Atlas Magnetics
              PMICs include:
            </Fade>
            <br />
            <Fade>
              1. Integrated Inductors for smallest footprints and thinnest
              solutions. These ICs contain fully integrated magnetics, not
              System-in-Packaging (SiP)
            </Fade>
            <br />
            <Fade>
              2. Advanced DC/DC architectures offering very high efficiencies and
              other benefits. Architectures include Multi-phase, high-frequency
              switching, Single Inductor Multiple Output (SIMO), and
              transformer-based DC/DC circuits.
            </Fade>
            <br />
            <Fade>
              3. Fast, Programmable, Low-Power Asynchronous control and mixed
              signal sensing for seamless interrupts, easy power sequencing , and
              unmatched low-power consumption.
            </Fade>
            <br />
            <Fade>
              All PMICs are built around a cost structure equal to or better than
              discrete solutions. Atlas Magnetics was founded on the concept of
              using a single process and packaging type, coupled with identical
              testers and handlers, to drive high quality practices and best
              high-volume cost. See our Quality Commitment for details.
            </Fade>
          </p>
        </div>
      </div>
      <div className="services-supply">
        <div className="services-supply-text">
          <Fade>
            <h4>Integrated and Standalone Inductors</h4>
          </Fade>
          <p>
            <Fade>
              Contact Us above to discuss your product request.
            </Fade>
            <br />
            <Fade>
              Atlas Magnetics is making its integrated inductor technology
              available to the semiconductor IC design community. Until early 2023
              all standalone and integrated inductor projects will be undertaken
              for completely custom, proprietary applications for individual
              end-customers. Atlas Magnetics is eager to discuss these
              collaborations with any groups that are a good fit for the values
              and considerations described below.
            </Fade>
            <Fade>
              <h3>Unique Values of Atlas Custom Inductors</h3>
            </Fade>
            <Fade>
              <b>Thin:</b> AM’s inductors are 0.18um to 0.36um of total thickness. The ideal inductor shape is a cube or sphere so discrete inductors with no height limitations will have a natural advantage vs. thin planar inductors when comparing inductance per unit area. However, since the inductor is designed to be placed under the driving IC, the net PCB board area is usually 30-60% smaller. Further, Planar inductors use flat wide copper coils with reduced skin effect so the impedance with increasing frequency is competitive and often superior to classic wire wound inductors.
            </Fade>
            <br />
            <Fade>
              <b>IC packaging compatible:</b> AM’s inductors are electroplated using in common semiconductor substrate material, Pb free, RoHs compatible, conflict free. This allows for complete power module products that are tested at the factory, less application support, and usually less EMI at the same height as the driver IC. All materials used in the AM’s inductors are common to the semiconductor industry.
            </Fade>
            <br />
            <Fade>
              <b>Cost effective:</b> For the right volume AM’s inductors are
              competitive to discrete inductors from the highest volume vendors
              while providing new capabilities not possible with simple discrete
              inductors.
            </Fade>
            <br />
            <Fade>
              <b>Easy to Multi Channel and Multi Phase:</b> Since AM inductors are
              planar and built with electroplating processes multiple inductors
              can be designed for a single final package. Thus, multichannel ICs
              such as PMICs and SOCs which need multiple inductors are excellent
              candidates for Atlas Magnetics’ technology.
            </Fade>
            <br />
            <Fade>
              <b>Ultra Flexible Geometry:</b> Various inductor shapes and
              orientations can be realized to minimize crosstalk, field
              interference, or operation frequency. In addition, transformers,
              couple inductors, multi-tap coils can all be manufactured at
              market-leading sizes for little to no additional cost.
            </Fade>
            <Fade>
              <h3>Considerations</h3>
              <b>Large Manufacturing Lots:</b> Our manufacturing panel “wafer”
              containing the inductors on which an IC die can be mounted, over
              molded, and singulated into QFN style ICs contains up to 500,000
              units per panel. This size allows Atlas Magnetics to offer very
              competitive pricing in high volume opportunities. But the massive panel size also means that opportunities where the total volume consumes less than a full panel are not as competitive. Each new project lot set up requires a roughly $20,000 USD charge to assemble and prepare the required machinery, which can be amortized across production lots. The specific lot charge will vary depending upon the complexity of the design.
            </Fade>
            <br />
            <Fade>
              <b>Inductance Limit per phase:</b> The magnetic devices are planar and so there is a point of diminishing return with respect to inductance increase by continuing to increase the X and Y dimension of the inductor. For applications that require higher inductance it is recommended to use multiple inductors and switch to a multiphase type of architecture. There is no additional cost from an inductor(s) perspective while performance will be improved. The maximum inductance possible as determined by economics is ~1 µH per phase.
            </Fade>
            <br />
            <Fade>
              <b>Current Density:</b> The current density of the inductors is highly dependent on the inductors DCR which is proportional to total inductance. As such the current density can vary from 0.8A/mm2 to well over 10A/mm2. Atlas Magnetics has proprietary designs and the associated patents pending to engineer the right inductor for high current applications.
            </Fade>
            <br />
            <Fade>
              <b>Optimal Frequency Range:</b> Our first-generation magnetic core inductors generally have a maximum frequency of between 2-8 MHz for good efficiency in DC/DC applications. Atlas Magnetics’ 2nd generation magnetic cores extend the frequency range to as high as 200MHz with 50-70MHz easily achievable. R&D work on increasing both the frequency response and current density of inductor offerings and expects to increase max frequency by 10x and current density by 3x before 2023.
            </Fade>
            <br />
            <Fade>
              <b>Q:</b> Our first-generation inductors usually achieve between 10-13 maximum Q around 2-4MHz. Our second-generation inductors have Q’s that can be accurately engineered between 16 and 35 depending on the frequency performance desired and manufacturing cost.
            </Fade>
            <br />
            <Fade>
              <h3>Typical Performance Benchmarks</h3>
              Inductance values: 10 nH to 1000nH
            </Fade>
            <br />
            <Fade>
              Inductance process variation:  +/-20%, this can be reduced at the expensive of total inductance. So +/-20% at 1000nH = +/-10% at 500nH, everything else being equal.
            </Fade>
            <br />
            <Fade>
              Inductance over temperature: -15% at -40C, +15% at 85C
            </Fade>
            <br />
            <Fade>
              DCR: 2.5 to 100 mohms in a single phase. For lower values, please consider a multiphase architecture
            </Fade>
            <br />
            <Fade>
              Current ratings: 0 to 1.5A per phase for 500nH, higher currents can be achieved with multiphase architecture. 3A per phase for 250 nH. 6A per phase for 125nH and so on.
            </Fade>
          </p>
        </div>
      </div>
    </div>
  </>);
};

export default Services;
