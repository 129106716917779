import { AUTH_TOKEN } from "../const";

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};
export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};
export const getUser = () => {
  return localStorage.getItem("user");
};
export const removeUser = () => {
  localStorage.removeItem("user");
};
export const setUser = (val) => {
  localStorage.setItem("user", val);
};
