import React from 'react';

const EmailRedirector = ({ emailAddress }) => {
    const redirectToEmailClient = () => {


        const gmailRegex = /@gmail\.com$/i;
        const yahooRegex = /@yahoo\.com$/i;
        const outlookRegex = /@(hotmail|outlook|live)\.(com|co\.uk|com\.au)$/i;

        if (gmailRegex.test(emailAddress)) {
            window.location.href = `https://mail.google.com/mail/u/0/#inbox`;
        } else if (yahooRegex.test(emailAddress)) {
            window.location.href = `https://compose.mail.yahoo.com`;
        } else if (outlookRegex.test(emailAddress)) {
            window.location.href = `https://outlook.live.com/owa/?path=/mail/action/`;
        } else {
            // For other email providers, redirect to a generic "mailto" link
            window.location.href = `mailto:${encodeURIComponent(emailAddress)}`;
        }
    };

    return (
        <>
            <a className="globalBtn login-container-loginForm-btn" href="#" onClick={() => redirectToEmailClient()} >Open mail client</a>
        </>
    );
};

export default EmailRedirector;