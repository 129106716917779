import React, { useEffect, useState } from "react";

import InputAdornment from '@material-ui/core/InputAdornment';
import password from "../../assets/icons/profile/Lock.svg";
import deleteImg from "../../assets/icons/profile/delete.svg";
import avatar from "../../assets/icons/profile/avatar.svg";
import TextField from "@material-ui/core/TextField";
import Avatar from "@material-ui/core/Avatar";

import { useAuthContext } from "../../context/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from "react-hook-form";
import { postChangeSend } from "../../slices/changePass";
import { toastError, toastSuccess } from "../../components/Toasts";
import { setToken } from "../../auth";

import "./index.scss";
import LogOutComponent from "../../components/LogOut";

const ChangePassPage = () => {
  const { user, logOut, setUser } = useAuthContext();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const [error, setError] = useState(null);
  const changeDataSend = useSelector((state) => state.changeSend);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  const onSubmit = (data, e) => {
      dispatch(postChangeSend(data));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onError = (errors, e) => console.log(errors, e);

  useEffect(() => {
    if(changeDataSend?.loading === "idle"){
        const err = changeDataSend?.error?.error?.message
        if (err) {
            setError(err)
          } 
    }
    
},[changeDataSend.loading])

useEffect(() => {
    if(error && changeDataSend.loading === "idle") {
        toastError(error);
    } 
    
},[error, changeDataSend.loading])

  useEffect(() => {
    if(changeDataSend?.loading === "idle"){
        if (changeDataSend?.data?.user) {
          setUser(changeDataSend?.data?.user);
          setToken(changeDataSend?.data?.jwt);
          toastSuccess("Password changed");
          reset();
          } 
    }
    
},[changeDataSend.loading])

  return (
    <div className="changePass">
      <div className="changePass-container">
        <div className="changePass-container-left">
          <div className="changePass-container-left-info">
            <Avatar className="changePass-container-left-info-avatar" alt="Remy Sharp" src={avatar} />
            <div className="changePass-container-left-info-block">
            <h1 className="changePass-container-left-info-name">{`${user?.firstName} ${user?.lastName}`}</h1>
            <h2 className="changePass-container-left-info-email">{user?.email}</h2>
            </div>
          </div>
          <ul className="changePass-container-left-list">
            <li>
              <img src={password} alt="" />
              <div className="changePass-container-left-list-text">
                <Link to={"/change-password"}>Change password</Link>
              </div>
            </li>
            <li>
              <img src={deleteImg} alt="" />
              <div className="changePass-container-left-list-text">
                <Link to={"/delete-account"}>Delate account</Link>
              </div>
            </li>
            <div className="changePass-container-left-list-text-margin" />
            <LogOutComponent />
          </ul>
        </div>
        <div className="changePass-container-main">
        <div className="changePass-container-main-changePass">
          <h1>Change password</h1>
          <div className="contact-form-inputs">
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <div className="contact-form-inputs-main">
                <TextField
                  required
                  label="Current password"
                  margin="normal"
                  variant="filled"
                  type={showOldPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" onClick={handleClickShowOldPassword} onMouseDown={handleMouseDownPassword}>
                        <FontAwesomeIcon icon={!showOldPassword ? faEyeSlash : faEye} className="login-container-loginForm-passEye" />
                      </InputAdornment>
                    ),
                  }}
                  {...register("currentPassword")}
                />
                <TextField
                  required
                  label="New password"
                  margin="normal"
                  variant="filled"
                  type={showNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" onClick={handleClickShowNewPassword} onMouseDown={handleMouseDownPassword}>
                        <FontAwesomeIcon icon={!showNewPassword ? faEyeSlash : faEye} className="login-container-loginForm-passEye" />
                      </InputAdornment>
                    ),
                  }}
                  {...register("password")}
                />
                <TextField
                  required
                  label="Confirm new password"
                  margin="normal"
                  variant="filled"
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" onClick={handleClickShowConfirmPassword} onMouseDown={handleMouseDownPassword}>
                        <FontAwesomeIcon icon={!showConfirmPassword ? faEyeSlash : faEye} className="login-container-loginForm-passEye" />
                      </InputAdornment>
                    ),
                  }}
                  {...register("passwordConfirmation")}
                />

                <button className="globalBtn login-container-loginForm-btn">Submit</button>
              </div>
            </form>
          </div>
          </div>
        </div>
        <br/>
          <br/>

        <ul className="profile-container-left-list mobile-view">

        <LogOutComponent />
            </ul>
      </div>
    </div>
  );
};

export default ChangePassPage;
