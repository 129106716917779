import MapAbout from "../../components/maps/mapAbout";
import { Fade } from "react-reveal";
import { useWindow } from "../../useMobile";
import "./index.scss";
import { useEffect } from "react";
import SEO from "../../components/seo";

const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const header = useWindow();
  return (<>
  <SEO title={"About AM - Programmable Mixed-Signal µASICs, DC/DC modules, Power delivery | Atlas Magnetics"}  description={"Atlas Magnetics produces low cost programmable mixed-signal µASICs, thin DC/DC converters, PMIC, load switch IC for various applications. Contact us for custom IC solutions."} type={"page"} keywords={"Atlas Magnetics, Inductors, IC, semiconductors, power delivery IC, mixed signal IC, programmable IC, ASIC, µASICs, micro Asic, FPGA"} />
    <div className="about">
      <div className="about-header">
        <Fade>
        <h2>About AM</h2>
        </Fade>
      </div>
      <div className="about-description">
      <Fade>
        <h4>Our Expertise – Silicon + Advanced Packaging</h4>
        </Fade>
        <p>
        <Fade>
          Atlas Magnetics is lead by silicon and packaging veterans with a track
          record of leading multiple successful startups which have shipped
          billions of ICs to tier-1 OEMs.
          </Fade>
          <br />
          <Fade>
          Notable backgrounds include management at Nvidia, Cypress SoC, Silego
          (now part of Renesas), and MPS. This background in advanced packaging,
          configurability, and monolithic power delivery drives the company
          vision of merging innovative circuit design with advanced materials
          for new products in the power, programmable logic, and RF markets.
          </Fade>
        </p>
      </div>
      <div className="about-images">
        <img src="https://cdn.atlasmagnetics.com/vision.png" alt="vision" />
        <div className="about-images-text">
        <Fade>
          <h4>Our Vision</h4>
          </Fade>
          <p>
          <img src="https://cdn.atlasmagnetics.com/vision.png" alt="vision" />
          <Fade>
            Atlas Magnetics was founded with a $23.4M USD Series A in June 2021
            to address one of the few remaining problems of modern electronics –
            the massive number of passive components to support core chips and
            functions. Our vision is to reduce customer board size. As
            integrated circuits continue to shrink, the relative size impact of
            the required passive components, support ICs, and routing within a
            system becomes more and more significant. Furthermore, guaranteeing
            supply capacity is more difficult for each additional component
            within the system.
            </Fade>
            <br />
            <Fade>
            The Atlas Magnetics approach is straightforward – consolidating more
            system functions at a competitive price requires rethinking how
            components are incorporated within an IC. Rather than sourcing 3rd
            -party components Atlas Magnetics focuses on improving the materials
            and techniques within the packaging to enable passive component
            development within the IC substrate.
            </Fade>
          </p>
        </div>
      </div>
      <div className="about-supply">
        <div className="about-supply-text">
        <Fade>
          <h4>We Merge Innovative Silicon Design with Advanced Materials</h4>
          </Fade>
          <img src="https://cdn.atlasmagnetics.com/materials.png" alt="materials" />
          <p>
          <Fade>
            Modern, high-value passive components aren’t added onto silicon, and
            it’s easy to see why. As process technology improves, and transistor
            sizes decrease, the average price of a wafer skyrockets. Unlike
            digital gates or power MOSFETs the passive components don’t make
            significant enough improvements in these new technologies to justify
            their addition onto the silicon.
            </Fade>
            <br/>
            <Fade>
            Atlas Magnetics approaches this
            problem from a package-level perspective. Modern packaging
            techniques combined with proprietary materials allow Atlas Magnetics
            to build passive components within the package substrate. The result
            is cheaper, more reliable, and smaller than co-packaging multiple
            components.
            </Fade>
          </p>
        </div>
        <img src="https://cdn.atlasmagnetics.com/materials.png" alt="materials" />
      </div>
    </div>
    {header ? <MapAbout/> : null}
    
    </>
  );
};

export default About;
