import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthProvider from './providers/auth';
import { store } from './redux';
import { Provider as ReduxProvider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <ReduxProvider store={store}>
  <AuthProvider>
    <Router>
      <App />
    </Router>
  </AuthProvider>
  </ReduxProvider>
);

