import React from 'react';
import { Helmet } from "react-helmet-async";
import logo from "../../assets/images/bigLogo.svg";


const SEO = (props) => {
    return (
        <Helmet>
            <meta charset="utf-8" />
            <title>{props.title}</title>
            <link rel="icon" href={logo}  />
            <meta name="viewport" content="width=device-width, initial-scale=1" />

            <link rel="apple-touch-icon" href={logo} />
            <meta name='description' content={props.description} />
            <meta name="keywords" content={props.keywords} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={props.title} />
            <meta property="og:description" content={props.description} />
            <meta property="og:image:type" content="image/*" />
            <meta property="og:image" content={logo} />
            <meta property="og:type" content="article" />
            <meta property="og:site_name" content="AtlasMagnetics" />

            <meta property="twitter:site" content="AtlasMagnetics" />
            <meta property="twitter:image" content={logo}/>
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:image:src" content={logo} />

            <meta name="twitter:card" content={props.type} />
            <meta name="twitter:title" content={props.title} />
            <meta name="twitter:description" content={props.description} />
            <link rel="canonical" href={window.location.href} />
        </Helmet>
    
    )
}
export default SEO;