import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../context/auth";
import { getToken, removeToken, getUser, setUser, removeUser } from "../../auth";
import { getApiUser, reset } from "../../slices/userSlice";

const AuthProvider = ({ children }) => {
  const authToken = getToken();
  const userData = getUser();
  const dispatch = useDispatch();
  const userStateData = useSelector((state) => state.user);
  const isLoading = userStateData.done;

  const [user, setGuser] = useState(null);

  const handleUser = (user) => {
    setUser(JSON.stringify(user));
    setGuser(user);
  };

  const handleLogOut = () => {
    removeToken();
    removeUser();
    setGuser(null);
    reset();
  };

  useEffect(() => {
    if (authToken && userData) {
      dispatch(getApiUser());
    }
  }, [authToken]);

  useEffect(() => {
    if (authToken && isLoading) {
      setUser(JSON.stringify(userStateData.data));
      setGuser(userStateData.data);
    }
  }, [userStateData, authToken, isLoading]);

  return (
    <AuthContext.Provider value={{ user, setUser: handleUser, isLoading, logOut: handleLogOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;