import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./modules/Home";
import Quality from "./modules/Quality";
import About from "./modules/About";
import Asic from "./modules/Asic";
import Power from "./modules/Power";
import Services from "./modules/Services";
import Article from "./modules/Article";
import Contact from "./modules/Contact";
import { ROUTES } from "./const";
import Policy from "./modules/Policy";
import NotFound from "./components/404";
import Edea from './modules/Edea';
import DownloadPage from './modules/Download';
import RegLoginPage from './modules/RegisterLogin';
import EmailPage from './modules/Email';
import LoginPage from './modules/Login';
import RegisterPage from './modules/Register';

import ReactGA from 'react-ga';
import { createBrowserHistory } from "history";
import ToastInit from './components/ToastInit';
import { HelmetProvider } from "react-helmet-async";
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-toastify/dist/ReactToastify.css';
import "react-toggle/style.css"
import 'video-react/dist/video-react.css';
import "./styles/index.scss";
import { useAuthContext } from './context/auth';
import ProfilePage from './modules/Profile';
import ArticleUasic from './modules/ArticleUasic';
import ForgotPage from './modules/ForgotPassport';
import ResetPage from './modules/ResetPassport';
import ChangePassPage from './modules/ChangePassword';
import DeletePage from './modules/DeleteAccount';

ReactGA.initialize("G-19KN3FBCP6");

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const App = () => {
  const { root, quality, about, asic, power, services, policy, article, contact, edea, deleteRoute, reg, login,change, prepare, profile, confirm, articleUasic, forgot, reset } = ROUTES;

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const { user } = useAuthContext();
  
  return (
    <>
      <HelmetProvider>
          <Header />
          <Routes>
            <Route path={root} element={<Home />} />
            <Route path={quality} element={<Quality />} />
            <Route path={about} element={<About />} />
            <Route path={asic} element={<Asic />} />
            <Route path={power} element={<Power />} />
            <Route path={services} element={<Services />} />
            <Route path={policy} element={<Policy />} />
            <Route path={article} element={<Article />} />
            <Route path={articleUasic} element={<ArticleUasic/>} />
            <Route path={contact} element={<Contact />} />
            <Route path={edea} element={<Edea />} />
            <Route path={profile} element={user ? <ProfilePage /> : <Navigate to={login} />} />
            <Route path={prepare} element={user ? <DownloadPage /> : <RegLoginPage />} />
            <Route path={reg} element={<RegisterPage />} />
            <Route path={login} element={<LoginPage />} />
            <Route path={confirm} element={<EmailPage />} />
            <Route path={forgot} element={<ForgotPage />} />
            <Route path={reset} element={<ResetPage/>} />
            <Route path={change} element={user ? <ChangePassPage/> : <Navigate to={login} />}/>
            <Route path={deleteRoute} element={user ? <DeletePage/> : <Navigate to={login} />} />
            <Route path="*" element={<NotFound />} /> 
          </Routes>
          <Footer />
          <ToastInit />
      </HelmetProvider>
    </>
  );

};

export default App;
