import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { postResetSend, reset } from '../../slices/resetSlice';

import { useForm } from "react-hook-form";
import { setToken } from '../../auth';
import { useAuthContext } from '../../context/auth';
import { useEffect } from 'react';
import { toastError, toastSuccess } from '../../components/Toasts';
import "./index.scss";



const ResetPage = () => {
    const [showConfirmPassword, setShowPasswordConfirm] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams()
    const { register, handleSubmit } = useForm();
    const [error, setError] = React.useState(null);
    const resetDataSend = useSelector((state) => state.resetSend);
    const { setUser, user } = useAuthContext()
    const navigator = useNavigate();
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickConfirmShowPassword = () => setShowPasswordConfirm((show) => !show);
    
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    
    const onSubmit = (data, e) => {
        const creds = {
            ...data,
            code: searchParams.get('code')
        }
        dispatch(postResetSend(creds));
    };

    useEffect(() => {
        if(resetDataSend?.loading === "idle"){
            console.log(resetDataSend)
            const err = resetDataSend?.error?.error?.message
            if (err) {
                setError(err)
            } else {
                setUser(resetDataSend?.data?.user)
                setToken(resetDataSend?.data?.jwt)

            }
        }
        
    },[resetDataSend.loading])

    useEffect(() => {
        if(error && resetDataSend.loading === "idle") {
            toastError(error)
        }
        reset()
    },[error, resetDataSend.loading])

    
    useEffect(() => {
        if(resetDataSend.loading === "idle" && resetDataSend.data?.ok) {
            toastSuccess('Password reset email sent')
        }
        reset()
    },[resetDataSend])
      useEffect(() => {
        if (user){
            navigator('/profile');
        }
    }, [user])
    const onError = (errors, e) => console.log(errors, e);

    return (
        <div className="reset">
            <div className="reset-container">
                
                <div className="reset-container-loginForm">
                    <h1>Reset password</h1>
                    <div className="contact-form-inputs">
                    <form onSubmit={handleSubmit(onSubmit, onError)}>
                        <div className="contact-form-inputs-main">
                        <TextField required
                                label="Password"
                                margin="normal"
                                variant="filled"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{endAdornment: (
                                    <InputAdornment position='end' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                        
                                      <FontAwesomeIcon icon={!showPassword ? faEyeSlash : faEye} className='login-container-loginForm-passEye'/>
                                    
                                    </InputAdornment>
                                )}}
                                {...register("password")}

                            />
                                                      <TextField required
                                label="Confirm password"
                                margin="normal"
                                variant="filled"
                                type={showConfirmPassword ? 'text' : 'password'}
                                InputProps={{endAdornment: (
                                    <InputAdornment position='end' onClick={handleClickConfirmShowPassword} onMouseDown={handleMouseDownPassword}>
                                        
                                      <FontAwesomeIcon icon={!showConfirmPassword ? faEyeSlash : faEye} className='login-container-loginForm-passEye'/>
                                    
                                    </InputAdornment>
                                )}}
                                {...register("passwordConfirmation")}

                            />
                            <button className="globalBtn reset-container-loginForm-btn" >Reset password</button>
                            <br/>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPage;
