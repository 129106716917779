import React, { useEffect, useState } from "react";
import { useWindow } from "../../useMobile";
import { Link } from "react-router-dom";
import VideoLoder from "../../components/VideoLoader";
import ImageGallery from "react-image-gallery";
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Fade } from "react-reveal";
import Seo from "../../components/seo";
import "./index.scss";

const Home = () => {
  const mobile = useWindow();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [imageIndex, setImageIndex] = useState(0)
  const images = [
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 1.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 1.jpg",
    },
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 2.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 2.jpg",
    },
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 3.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 3.jpg",
    },
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 4.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 4.jpg",
    },
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 5.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 5.jpg",
    },
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 6.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 6.jpg",
    },
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 7.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 7.jpg",
    },
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 8.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 8.jpg",
    },
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 9.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 9.jpg",
    },
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 10.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 10.jpg",
    },
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 11.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 11.jpg",
    },
    {
      original: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 12.jpg",
      thumbnail: "https://cdn.atlasmagnetics.com/gallery/Our Approach_img 12.jpg",
    },
  ];
  const images_solve = [
    {
      original: "https://cdn.atlasmagnetics.com/laminate.png",
      thumbnail: "https://cdn.atlasmagnetics.com/laminate.png",
      text: "Solving One of the Greatest Problems in Modern Electronics",
      link: "/article"
    },
    {
      original: "https://cdn.atlasmagnetics.com/inductor.png",
      thumbnail: "https://cdn.atlasmagnetics.com/inductor.png",
      text: "Solving One of the Greatest Problems in Modern Electronics",
      link: "/article"
    },
    {
      original: "https://cdn.atlasmagnetics.com/schema.png",
      thumbnail: "https://cdn.atlasmagnetics.com/schema.png",
      text: "Free schematics capture tools Atlas EDA is available for download",
      link: "/edea"
    },
    {
      original: "https://cdn.atlasmagnetics.com/chip.png",
      thumbnail: "https://cdn.atlasmagnetics.com/chip.png",
      text: "8 pin uASIC released",
      link: "/articleUasic"
    },
  ]

  return mobile ? (<>
    <Seo title={"Programmable Mixed-Signal µASICs, DC/DC modules, Power delivery | Atlas Magnetics"}  description={"Atlas Magnetics produces low cost programmable mixed-signal µASICs, thin DC/DC converters, PMIC, load switch IC for various applications. Contact us for custom IC solutions."} type={"page"} keywords={"Atlas Magnetics, Inductors, IC, semiconductors, power delivery IC, mixed signal IC, programmable IC, ASIC, µASICs, micro Asic, FPGA"} />
    <div className="home">
      <VideoLoder src="https://cdn.atlasmagnetics.com/video/main.mp4" poster="https://cdn.atlasmagnetics.com/poster.png" />
      <Fade>
        <div className="home-introduction">

          <div className="home-introduction-text">
            <p>
              Small and Thin Programmable Integrated Circuits
              <p>
                <span>with integrated magnetics for power, analog, and RF</span>

              </p>
            </p>

            <Link className="header-contact" to="/about">
              Learn More
            </Link>
          </div>
          <HashLink smooth to="#home-solving" className="block-link header-contact"><FontAwesomeIcon icon={faAngleDown} /></HashLink>
        </div >
      </Fade>
      <div id="home-solving" />
      <div className="home-solving">
        <ImageGallery
          items={images_solve}
          originalHeight={"100%"}
          showNav={false}
          showThumbnails={false}
          showBullets={true}
          showPlayButton={false}
          autoPlay={true}
          slideInterval={4000}
          slideDuration={800}
          transitionDuration={800}
          onBeforeSlide={(index)=> setImageIndex(index)}
          showFullscreenButton={false}
        />
        <Fade>
          <div className="home-solving-text">
            <p>{images_solve[imageIndex].text}</p>
            <Link className="header-contact" to={images_solve[imageIndex].link}>
              Learn More
            </Link>
          </div>
        </Fade>
      </div>
      <div className="home-atlas">
        <Fade>
          <div className="home-atlas-text">
            <span>
              Atlas Magnetics <img src="https://cdn.atlasmagnetics.com/bigLogo.svg" alt="logo" />
            </span>
            <p>
            We remove passives and low-value ICs from your products using cutting-edge manufacturing techniques and low-cost configurability. 
New Way to Integrate Passives: Using modern packaging and proprietary materials we build integrated inductors in-package. No third party components. No supply concerns. No stacked costs.
Configurability without the Investment: Design and configure your own custom ASICs using our uASIC and DC/DC module product families. Using the Free Atlas EDA software you can create a custom design in minutes, get programmed samples in days, and go to production in weeks. No NRE and no hidden costs.

              <br />
              <br />
              <br />
              The delivery of high-quality, reliable ICs is the top priority of
              Atlas Magnetics. Through maintaining the same foundry and other
              supply chain members across all products Atlas Magnetics quickly
              provides new products with minimal risk.
              <br />
              <br />
              <br />
              Questions about our upcoming products? Please reach out to us in the
              Contact Form.
            </p>

          </div>
        </Fade>
        <img src="https://cdn.atlasmagnetics.com/building.png" alt="building" />
      </div>

      <div className="home-approach">
        <Fade>
          <div className="home-approach-text">
            <h4>Our Approach</h4>
            <p>
              Highest-quality IC design principles Partnership with Established
              Semiconductor Company for Fulfillment and Quality Standards
            </p>
            <Link className="header-contact" to="/quality">
              COMMITMENT TO QUALITY
            </Link>
          </div>
        </Fade>
        <img src="https://cdn.atlasmagnetics.com/homeImages.png" alt="inside" />
      </div>

    </div>
    </>) : (<>
    <Seo title={"Programmable Mixed-Signal µASICs, DC/DC modules, Power delivery | Atlas Magnetics"}  description={"Atlas Magnetics produces low cost programmable mixed-signal µASICs, thin DC/DC converters, PMIC, load switch IC for various applications. Contact us for custom IC solutions."} type={"page"} keywords={"Atlas Magnetics, Inductors, IC, semiconductors, power delivery IC, mixed signal IC, programmable IC, ASIC, µASICs, micro Asic, FPGA"} />
    <div className="home-mobile">
      <img className="home-mobile-back" src="https://cdn.atlasmagnetics.com/home-mobile.png" alt="background" />

      <div className="home-mobile-intro">
        <Fade>
          <h4>Small and thin Programmable Integrated Circuits</h4>
          <p>with integrated magnetics for power, analog, and RF</p>
          <Link className="header-contact" to="/about">
            Learn More
          </Link>
        </Fade>
      </div>

      <div className="home-mobile-solving">
        <Fade>
          <div className="home-mobile-solving-text">
            <h4>Solving One of the Greatest Problems in Modern Electronics</h4>
            <Link className="header-contact" to="/article">
              Learn More
            </Link>
          </div>
        </Fade>
      </div>
      <div className="home-mobile-atlas">

        <span>

          Atlas Magnetics <img src="https://cdn.atlasmagnetics.com/bigLogo.svg" alt="logo" />

        </span>

        <img src="https://cdn.atlasmagnetics.com/building.png" alt="building" />
        <Fade>
          <p>
            We remove components from system boards by integrating features and
            passive components in a truly cost-effective approach. Using modern
            packaging techniques and proprietary materials Atlas Magnetics makes
            ICs which include passive components without the price of large on-die
            passives or co-packaged modules. Upcoming products will include
            solutions for Power Delivery, Programmable Logic, RF, and more.
            <br />
            <br />
            The delivery of high-quality, reliable ICs is the top priority of
            Atlas Magnetics. Through maintaining the same foundry and other supply
            chain members across all products Atlas Magnetics quickly provides new
            products with minimal risk.
            <br />
            <br />
            Questions about our upcoming products? Please reach out to us in the
            Contact Form.
          </p>
        </Fade>
      </div>

      <div className="home-mobile-approach">
        <h4>Our Approach</h4>
        <Fade>
          <p>
            Highest-quality IC design principles Partnership with Established
            Semiconductor Company for Fulfillment and Quality Standards
          </p>
        </Fade>
      </div>
      <ImageGallery
        items={images}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
      />
      <div className='home-mobile-contact'>
        <Link className="home-mobile-contact-button" to="/quality">
          COMMITMENT TO QUALITY
        </Link>
      </div>
    </div>
    </>);
};

export default Home;
