import "./index.scss";
import download from "../../assets/images/download.svg";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toastError, toastSuccess } from "../../components/Toasts";
import axios from "axios";
import { Fade } from "react-reveal";

const DownloadPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [searchParams] = useSearchParams();
  const [seconds, setSeconds] = useState(3);
  const [text, setText] = useState(null);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    const startDownload = () => {
      console.log("Download started!");
      downloadFile(downloadUrl);
    };

    const timer = setTimeout(() => {
      if (seconds !== 0) {
        setSeconds(seconds - 1);
        if (seconds === 1) {
          startDownload();
        }
      }
    }, 1000);

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, [seconds]);

  useEffect(() => {
    switch (searchParams.get("download")) {
      case "mac":
        setText("Atlas EDA for Mac");
        setFileName("am-eda-mac.dmg");
        setDownloadUrl("https://download.atlasmagnetics.com/atlas-eda?os_type=macos");

        break;
      case "win":
        setText("Atlas EDA for Windows");
        setFileName("am-eda-win.zip");
        setDownloadUrl("https://download.atlasmagnetics.com/atlas-eda?os_type=windows");

        break;
      case "guide":
        setText("Atlas EDA guides");
        setFileName("user-guide_atlas-eda.pdf");
        setDownloadUrl("https://download.atlasmagnetics.com/files/user_guides/user-guide_atlas-eda.pdf");

        break;
      default:
        setText("Atlas EDA guides");
        setDownloadUrl("https://download.atlasmagnetics.com/files/user_guides/user-guide_atlas-eda.pdf");
    }
  }, []);

  const downloadFile = async (fileUrl) => {
    try {
      const link = document.createElement('a');
      link.href = fileUrl
      link.download = fileName; // Leave the download attribute empty for browser-generated filename
      link.click();

      toastSuccess(`Download ${text} started`);

      // Remove the anchor element after the download starts
    } catch (error) {
      console.error("Error downloading the file:", error);
      toastError(`Error downloading the file`);
    }
  };

  return (
    <div className="download">
       <Fade>
      <div className="download-container">
        <iframe id="my_iframe" className="download-container-frame"></iframe>
        <div className="download-container-text">
          <h1>Thank you!</h1>
          <p>
            Download {text} will start in a {seconds} seconds. <img src={download} alt="" />
          </p>
          <h5>
            By logging in, you agree to AM`s{" "}
            <Link className="regLogin-login" to={"/policy"}>
              privacy policy and terms of use.
            </Link>
          </h5>
        </div>
      </div>
      </Fade>
    </div>
  );
};

export default DownloadPage;
