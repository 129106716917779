const MapAboutBlockItem = ({
	location,
	products,
	posX,
	posY,
	img,
	textAlign,
}) => {
	const getRem = size => {
		return size / 16 + 'rem';
	};
	return (
		<div
			className='map-block-item'
			style={{ bottom: getRem(posY), left: getRem(posX) }}
		>
			<div className='map-block-item-content'>
				{img && <img src={img} alt='Location block item img' />}

				<h4
					style={{ textAlign: `${textAlign === 'right' ? 'right' : 'left'}` }}
				>
					{location}
				</h4>
				<h4>{products}</h4>
			</div>
		</div>
	);
};
export default MapAboutBlockItem;
